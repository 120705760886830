<template>
  <div class="card bg-secondary shadow">
    <div class="card-body">
      <h5 class="card-title">{{ product.display_name }}</h5>
      <hr>
      <p class="card-text">{{ product.description }}</p>
    </div>
    <div class="card-footer row small-text text-primary" v-if="this.jwt && rewardsCapable">
      <div class="col">
        <div>Installed</div>
        <div class="custom-control custom-switch pt-2">
          <input type="checkbox" class="custom-control-input" :id="product.sku + `_installed_cb`" @change="install" :checked="installed" :disabled="!installed && rewardCount >= 50">
          <label :for="product.sku + `_installed_cb`" class="custom-control-label"></label>
        </div>
      </div>
      <div class="col" v-if="installed">
        <div><label :for="product.sku + `_cost_input`">Cost</label></div>
        <div class="pl-1 pr-1">
          <input type="text" class="form-control form-control-sm" :id="product.sku + `_cost_input`" v-model="costInput">
        </div>
      </div>
      <div class="col" v-if="installed">
        <div><label :for="product.sku + `_cooldown_input`">Cooldown</label></div>
        <div class="pl-1 pr-1">
          <input type="text" class="form-control form-control-sm" :id="product.sku + `_cooldown_input`" v-model="cooldownInput">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

const skuPattern = new RegExp('CCSKU:([\\w\\-]+)$')
const numPattern = new RegExp(/\d/g)

export default {
name: "ProductCard",
  props: {
    product: Object
  },
  data: function () {
    return {
      cost: 20000,
      cooldown: 0,
      updateTimeout: null
    }
  },
  computed: {
    ...mapGetters({
      rewardCount: 'getRewardCount',
      ccRewards: 'getCCRewards',
      jwt: 'getToken',
      rewardsCapable: 'getRewardsCapable'
    }),
    reward: function () {
      for (let i = 0; i < this.ccRewards.length; i++) {
        const matches = this.ccRewards[i].prompt.match(skuPattern)
        if (matches && matches[1] === this.product.sku) {
          return this.ccRewards[i]
        }
      }
      return null
    },
    installed: function () {
      return this.reward !== null
    },
    costInput: {
      get() {
        if (!this.reward) {
          return this.cost
        }
        return this.reward.cost
      },
      set(val) {
        if (!val) {
          return
        }
        this.cost = val.match(numPattern).join('')
        this.reward.cost = val.match(numPattern).join('')
        if (this.updateTimeout) {
          clearTimeout(this.updateTimeout)
        }
        this.updateTimeout = setTimeout(() => {
          this.update()
        }, 600)
      }
    },
    cooldownInput: {
      get() {
        if (!this.reward) {
          return this.cooldown
        }
        return this.reward.global_cooldown_setting.global_cooldown_seconds
      },
      set(val) {
        if (!val) {
          return
        }
        this.cooldown = val.match(numPattern).join('')
        this.reward.global_cooldown_setting.global_cooldown_seconds = val.match(numPattern).join('')
        if (this.updateTimeout) {
          clearTimeout(this.updateTimeout)
        }
        this.updateTimeout = setTimeout(() => {
          this.update()
        }, 600)
      }
    }
  },
  methods: {
    ...mapActions([
        "loadRewards",
        "installReward",
        "uninstallReward",
        "updateReward"
    ]),
    install: function() {
      if (this.installed) {
        return this.uninstall()
      }
      return this.installReward({
        display_name: this.product.display_name,
        description: this.product.description + `\n\nCCSKU:${this.product.sku}`,
        cost: this.costInput,
        cooldown: this.cooldownInput
      })
    },
    uninstall: function () {
      this.uninstallReward(this.reward.id)
    },
    update: function () {
      if (!this.installed) {
        return
      }
      let updatedReward = this.reward
      updatedReward.cost = this.cost
      updatedReward.global_cooldown_seconds = this.cooldown
      updatedReward.is_global_cooldown_enabled = this.cooldown > 0
      this.updateReward(updatedReward)
    }
  }
}
</script>

<style scoped>
  .card {
    min-width: 270px;
  }
  .card-title {
    color: #FF550B;
  }
  hr {
    background-color: #ccc;
  }
  .row {
    text-align: center;
  }
  .small-text {
    font-size: 0.8rem;
  }
  .card-footer .col {
    padding: 0;
  }
  .card-footer .form-control-sm {
    padding: 0;
    height: calc(1em + 0.5rem + 2px);
    color: #e8e8e8;
    text-align: center;
    background-color: #666;
    border: 1px solid #e8e8e8;
  }
  .card-footer .custom-control.custom-switch.pt-2 {
    padding-top: 0.5rem !important;
  }
  .card-footer .col .pt-2 {
    padding-top: 0.6rem !important;
  }
</style>