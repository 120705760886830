<template>
  <div>
    <h2>Welcome to the Chaos!</h2>

    <div class="row p-3 text-center justify-content-center">
      <div class="card col-md m-2 p-4 shadow">
        <font-awesome-icon icon="dollar-sign" size="3x" class="w-100" />
        <h3 class="mt-4">Channel Revenue</h3>
      </div>
      <div class="card col-md m-2 p-4 shadow">
        <font-awesome-icon icon="comments" size="3x" class="w-100" />
        <h3 class="mt-4">Viewer Engagement</h3>
      </div>
      <div class="card col-md m-2 p-4 shadow">
        <font-awesome-icon icon="users" size="3x" class="w-100" />
        <h3 class="mt-4">Community Builder</h3>
      </div>
    </div>

    <p>
      Chaos Creator is a Twitch Integration built around the game ARK: Survival Evolved (PC: Steam Edition).
      Streamers are able to use this integration to engage their communities in a whole new way!
      Anything from casual play, to community contests, to all out war, it can all have a little Chaos added to the mix.
    </p>

    <p>
      There are two main aspects to Chaos Creator, the Twitch Bits Extension, and the Twitch Custom Rewards Integration.
      Streamers from small to big, can find a way to utilize Chaos Creator to enhance their streams and viewer engagement.
      Mix and match events between helpful, silly, low-impact, high-impact, and total chaos, tailored to your stream's session.
      With over 140 events, you can make the integration work for any stream's style.
    </p>

    <p>
      If you have any questions, suggestions, or just want to reach out, head over to the <router-link to="/contact">Contact Page</router-link> for Discord Server information.
    </p>


    <div class="embed-responsive embed-responsive-16by9">
      <video class="embed-responsive-item video-js" controls muted autoplay loop ref="videoPlayer">
        <source :src="`/static/video/clip_1.mp4`" type="video/mp4">
      </video>
    </div>

    <h3 class="pt-4" v-if="liveStreamers.length">Live Streamers</h3>
    <div class="card-deck p-3 justify-content-center" v-if="liveStreamers.length">
      <StreamerCard v-for="streamer in liveStreamers"
                    v-bind:key="streamer.broadcaster_id"
                    v-bind:broadcaster_id="streamer.broadcaster_id"
                    v-bind:broadcaster_name="streamer.broadcaster_name"
                    v-bind:game_name="streamer.game_name"
                    v-bind:game_id="streamer.game_id"
                    v-bind:title="streamer.title">
      </StreamerCard>
    </div>
  </div>
</template>

<script>
const axios = require('axios')
// use https://clipr.xyz/ for grabbing clip mp4
import videojs from 'video.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import {faDollarSign, faComments, faUsers} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import StreamerCard from '@/components/StreamerCard'

library.add(faDollarSign)
library.add(faComments)
library.add(faUsers)

export default {
  name: 'Home',
  components: {
    FontAwesomeIcon,
    StreamerCard
  },
  data: function() {
    return {
      player: null,
      liveStreamers: []
    }
  },
  methods: {
    getLiveStreamers: function() {
      axios.get('/api/v1/stats/live')
      .then(response => {
        this.liveStreamers = response.data
      })
    }
  },
  beforeMount() {
    this.getLiveStreamers()
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, {}, function onPlayerReady() {
      console.log('onPlayerReady', this)
    })
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>

<style scoped>
h3 {
  color: #FF550B;
}

.card {
  background-color: #404040;
}

.card-deck {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: .5rem;
}
</style>
