import axios from 'axios'

let skuPattern = new RegExp('CCSKU:([\\w\\-]+)$')

async function twitchCustomRewardsRequest(twitchId, twitchToken, twitchCID,
                                          method, additionalParams, data) {
    let params = {
        'broadcaster_id': twitchId
    }
    if (additionalParams) {
        params = {
            ...params,
            ...additionalParams
        }
    }
    let headers = {
        'Authorization': `Bearer ${twitchToken}`,
        'Client-Id': twitchCID
    }
    if (method.toLowerCase() in ['post', 'put', 'patch']) {
        headers['Content-Type'] = 'application/json'
    }
    return axios({
        method: method,
        url: 'https://api.twitch.tv/helix/channel_points/custom_rewards',
        headers: headers,
        params: params,
        data: data
    })
}

async function loadTwitchRewards(twitchId, twitchToken, twitchCID,
                                 only_manageable_rewards = false,
                                 rewardId = null) {
    let params = {
        'only_manageable_rewards': only_manageable_rewards
    }
    if (rewardId) {
        params['id'] = rewardId
    }
    return twitchCustomRewardsRequest(twitchId, twitchToken, twitchCID,
        'get', params)
}

async function installReward(twitchId, twitchToken, twitchCID, reward) {
    return twitchCustomRewardsRequest(twitchId, twitchToken, twitchCID,
        'post', null, reward)
}

const state = {
    rewards: [],
    ccRewards: [],
    rewardsCapable: null
}

const getters = {
    getRewardCount: state => {
        return state.rewards.length
    },
    getCCRewards: state => {
        return state.ccRewards
    },
    getRewardsCapable: state => {
        return state.rewardsCapable
    }
}

const actions = {
    async loadRewards({ commit, rootGetters }) {
        try {
            let response = await loadTwitchRewards(
                rootGetters.getTwitchUser['id'],
                rootGetters.getTwitchToken,
                rootGetters.getTwitchCID
            )
            commit('setRewards', response.data.data)
            response = await loadTwitchRewards(
                rootGetters.getTwitchUser['id'],
                rootGetters.getTwitchToken,
                rootGetters.getTwitchCID,
                true
            )
            let ccRewards = []
            response.data.data.forEach((reward) => {
                if (reward.prompt.match(skuPattern)) {
                    ccRewards.push(reward)
                }
            })
            commit('setCCRewards', ccRewards)
            commit('setCapability', true)
        } catch (error) {
            console.log('There was an error loading rewards from Twitch.', error)
            if (error.response.status === 403) {
                commit('setCapability', false)
            }
            return error
        }
    },
    async installReward({ commit, rootGetters }, reward) {
        reward = {
            title: reward.display_name,
            prompt: reward.description,
            cost: reward.cost,
            is_global_cooldown_enabled: reward.cooldown > 0,
            global_cooldown_seconds: reward.cooldown,
            is_enabled: rootGetters.getTwitchUser['streaming'],
            background_color: '#FF550B'
        }
        try {
            const response = await installReward(
                rootGetters.getTwitchUser['id'],
                rootGetters.getTwitchToken,
                rootGetters.getTwitchCID,
                reward
            )
            console.log(response.data)
            commit('addCCReward', response.data.data[0])
            return response.data.data[0]
        } catch (error) {
            console.log('There was an error installing the reward', error)
            return error
        }
    },
    async uninstallReward({ commit, rootGetters }, rewardId) {
        let params = {
            id: rewardId
        }
        try {
            await twitchCustomRewardsRequest(
                rootGetters.getTwitchUser['id'],
                rootGetters.getTwitchToken,
                rootGetters.getTwitchCID,
                'delete', params
            )
            commit('removeCCReward', rewardId)
            return true
        } catch (error) {
            console.log("There was an error deleting the Custom Reward.", error)
            return error
        }
    },
    async updateReward({ commit, rootGetters }, reward) {
        let params = {
            id: reward.id
        }
        try {
            const response = await twitchCustomRewardsRequest(
                rootGetters.getTwitchUser['id'],
                rootGetters.getTwitchToken,
                rootGetters.getTwitchCID,
                'patch', params, reward
            )
            commit('updateCCReward', response.data.data[0])
        } catch (error) {
            console.log("There was an error updating the Custom Reward", error)
            return error
        }
    },
    async uninstallAllRewards({ dispatch, state }) {
        let rewards = state.ccRewards
        rewards.forEach((reward) => {
            dispatch('uninstallReward', reward.id)
        })
    }
}

const mutations = {
    setRewards: (state, rewards) => {
        state.rewards = rewards
    },
    setCCRewards: (state, rewards) => {
        state.ccRewards = rewards
    },
    addCCReward: (state, reward) => {
        state.ccRewards.push(reward)
        state.rewards.push(reward)
    },
    updateCCReward: (state, reward) => {
      for (let i = 0; i < state.ccRewards.length; i++) {
          if (state.ccRewards[i].id === reward.id) {
              state.ccRewards.splice(i, 1, reward)
              break
          }
      }
        for (let i = 0; i < state.rewards.length; i++) {
            if (state.rewards[i].id === reward.id) {
                state.rewards.splice(i, 1, reward)
                break
            }
        }
    },
    removeCCReward: (state, rewardId) => {
        for (let i = 0; i < state.ccRewards.length; i++) {
            if (state.ccRewards[i].id === rewardId) {
                state.ccRewards.splice(i, 1)
                break
            }
        }
        for (let i = 0; i < state.rewards.length; i++) {
            if (state.rewards[i].id === rewardId) {
                state.rewards.splice(i, 1)
                break
            }
        }
    },
    setCapability: (state, capabilityState) => {
        state.rewardsCapable = capabilityState
    }
 }

export default {
    state,
    getters,
    actions,
    mutations
}
