<template>
  <b-container id="app" class="d-flex w-100 h-100 p-3 mx-auto flex-column">
    <header class="mb-auto">
      <nav class="navbar navbar-expand-lg">
        <router-link to="/" class="navbar-brand text-white"><img src="/static/img/logos/Small_FullTextLogo.png"></router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-dark">
            <span class="navbar-toggler-icon"></span>
          </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/how-it-works" class="nav-link">How It Works</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/events" class="nav-link">{{ eventsListText }}</router-link>
            </li>
            <li v-if="jwt" class="nav-item">
              <router-link to="/config" class="nav-link">Configuration</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link">Contact</router-link>
            </li>
          </ul>
          <LoginSteamButton />
          <LoginTwitchButton />
        </div>
      </nav>
    </header>
    <router-view />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoginTwitchButton from "@/components/LoginTwitchButton"
import LoginSteamButton from "@/components/LoginSteamButton"

export default {
  name: 'App',
  components: {
    LoginTwitchButton,
    LoginSteamButton
  },
  methods: {
    ...mapActions(['logout', 'refreshToken'])
  },
  computed: {
    ...mapGetters({
      jwt: 'getToken',
    }),
    eventsListText: function() {
      if (this.jwt) {
        return 'Events List'
      }
      return 'Events List'
    }
  },
  beforeMount() {
    this.refreshToken()
  }
}
</script>

<style>
</style>
