<template>
  <div class="card bg-secondary shadow">
    <img :src="`https://static-cdn.jtvnw.net/previews-ttv/live_user_${broadcaster_name.toLowerCase()}-530x340.jpg`" class="card-img-top">
    <div class="card-body">
      <h5 class="card-title">{{ broadcaster_name }}</h5>
      <hr>
      <p class="card-text">{{ title }}</p>
    </div>
      <a :href="`https://twitch.tv/${broadcaster_name}`" class="card-footer row small-text text-primary justify-content-center">Watch</a>
  </div>
</template>

<script>
export default {
  name: "StreamerCard",
  props: ['broadcaster_id', 'broadcaster_name', 'game_name', 'game_id', 'title']
}
</script>

<style scoped>

</style>