<template>
<div>
  <b-alert :show="jwt && !steamUser" variant="danger">
    You have no Steam Account associated with your Twitch Account.
    No events will trigger in-game until you do so.
    Please Login with Steam.
  </b-alert>

  <b-alert :show="jwt && !eosId" variant="danger">
    You have no EOS ASA ID associated with your Twitch Account.
    No events will trigger in-game (ARK: Survival Ascended) until you do so.
    Please add an EOS ASA ID to the <router-link to="/config">Configuration</router-link> page.
  </b-alert>
  <h1>Events</h1>

  <p>
    See <router-link to="/how-it-works">How It Works</router-link> for information on how to get setup with the companion mod and bot.
    <span v-if="!jwt">Once you are logged in with Twitch, you may install these Events as Custom Rewards through this interface.</span>
    Please keep in mind,  Streamers are only allowed 50 Custom Rewards (not just Chaos Creator Rewards) enabled on their Channel at a time.
    If your Channel is already at max limit, you will need to delete (not disable) existing Custom Rewards to make room for Chaos Creator Custom Rewards.
  </p>

  <p v-if="jwt">
    <strong>Note:</strong> Chaos Creator can only create, modify, and delete Custom Rewards created through this interface.<br>
    <strong>Note:</strong> Any modification of Chaos Creator Custom Rewards through the Twitch Custom Rewards interface is not supported, and likely to lead to malfunctions or unexpected behavior.
  </p>

  <p>Feel free to download and use our <a href="/files/resources/chaos_points_icons.zip" target="_blank">Chaos Points Icon Set</a>.</p>

  <p>
    These are of all the currently supported events. These are the same events supported by the Bits Extension as well.
  </p>

  <!-- Tab Card -->
  <div class="card text-center tab-card">
    <div class="card-header">
      <ul class="nav nav-tabs card-header-tabs">
        <li class="nav-item" v-for="category in categories" v-bind:key="category">
          <a v-bind:class="[activeCategory === category ? 'active' : '', 'nav-link']" href="#"
             @click="activeCategory = category">{{ category | capitalize }}</a>
        </li>
      </ul>
    </div>
    <div class="card-body">
      <div class="row mb-2" v-if="jwt && rewardsCapable">
        <div class="col">Custom Rewards: {{ rewardsCount }}/50</div>
        <div class="col">Chaos Creator Rewards: {{ ccRewards.length }}/{{ rewardsCount }}</div>
      </div>
      <div v-if="rewardsCapable === false" class="alert alert-danger">
        Your Twitch Account is not capable of Custom Rewards. You will not be able to use Chaos Creator until then.
      </div>
      <div class="row mb-2 p-2" v-if="ccRewards.length">
        <button class="btn btn-danger col" @click="uninstallAllRewards">Uninstall ALL Chaos Creator Rewards</button>
      </div>
      <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 justify-content-center">
        <ProductCard v-for="product in getProductsForCategory(activeCategory)"
                     v-bind:key="product.sku"
                     v-bind:product="product"
                     class="col mb-3 ml-1 mr-1">
        </ProductCard>
      </div>
    </div>

  </div>
</div>
</template>

<script>
const axios = require('axios')
import { mapGetters, mapActions } from 'vuex'
import ProductCard from "@/components/ProductCard";

export default {
  name: "EventsList",
  components: {
    ProductCard
  },
  data: function() {
    return {
      products: null,
      activeCategory: null
    }
  },
  filters: {
    capitalize: function(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  },
  computed: {
    ...mapGetters({
      jwt: 'getToken',
      twitchUser: 'getTwitchUser',
      twitchToken: 'getTwitchToken',
      twitchCID: 'getTwitchCID',
      steamUser: 'getSteamUser',
      rewardsCount: 'getRewardCount',
      ccRewards: 'getCCRewards',
      rewardsCapable: 'getRewardsCapable'
    }),
    categories: function () {
      let cats = []
      if (!this.products) {
        return []
      }
      this.products.forEach(product => {
        if (!cats.includes(product.category)) {
          cats.push(product.category)
        }
      })
      return cats
    }
  },
  methods: {
    ...mapActions([
      "loadRewards",
      "uninstallAllRewards"
    ]),
    getProducts: function() {
      axios.get('/api/v1/product')
      .then(response => {
        this.products = response.data
        this.activeCategory = this.categories[0]
      })
    },
    getProductsForCategory: function(category) {
      if (category === null) {
        return []
      }
      return this.products.filter(product => product.category === category)
    },
    getCustomRewards: async function(managedOnly) {
      try {
        const response = await axios.get('https://api.twitch.tv/helix/channel_points/custom_rewards', {
          params: {
            broadcaster_id: this.twitchUser['id'],
            only_manageable_rewards: managedOnly
          },
          headers: {
            'Authorization': `Bearer ${this.twitchToken}`,
            'Client-Id': this.twitchCID
          }
        })
        console.log(response)
      } catch (err) {
        console.log(err)
      }
    }
  },
  beforeMount() {
    this.getProducts()
    if (this.jwt) {
      this.loadRewards()
    }
  }
}
</script>

<style scoped>
h1, h2, h3, h4, h5 {
  color: #FF550B;
}

.nav-link.active {
  background-color: white;
  border: 1px solid black;
  border-bottom: 1px solid #FF550B;
}

.nav-link:hover {
  border: 1px solid #333;
}

.nav-link.active:hover {
  border: 1px solid #333;
  border-bottom: 1px solid #FF550B;
}

.tab-card {
  background-color: #606060;
  border: 1px solid black;
}

.tab-card .card-header {
  background-color: #ddd !important;
  border-bottom: 1px solid black;
}

</style>