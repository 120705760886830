import axios from 'axios'
import jwt from 'jsonwebtoken'
import Cookies from 'js-cookie'
import router from '@/router'

const state = {
  token: Cookies.get('jwt'),
  eosId: null,
  steamUser: null
}

const getters = {
  getToken: state => {
    return state.token
  },
  getTwitchUser: state => {
    if (!state.token) {
      return null
    }
    return jwt.decode(state.token)['twitch_user']
  },
  getTwitchToken: state => {
    if (!state.token) {
      return null
    }
    return jwt.decode(state.token)['tt']
  },
  getTwitchCID: state => {
    if (!state.token) {
      return null
    }
    return jwt.decode(state.token)['cid']
  },
  getEosId: state => {
    if (!state.token) {
      return null
    }
    if (state.eosId) {
      return state.eosId
    }
    return jwt.decode(state.token)['eos_id']
  },
  getSteamUser: state => {
    if (!state.token) {
      return null
    }
    if (state.steamUser) {
      return state.steamUser
    }
    return jwt.decode(state.token)['steam_user']
  }
}

const actions = {
  processToken({ dispatch, commit }, token) {
    commit('setToken', token)
    const exp = new Date((jwt.decode(token)['exp'] - 30) * 1000)
    Cookies.set('jwt', token, { expires: exp })
    setTimeout(dispatch, exp - new Date(), 'refreshToken')
  },
  async login({ dispatch }, code) {
    try {
      const response = await axios.post('/oauth2/token',
        {'code': code, 'redirect_uri': window.location.origin + window.location.pathname})

      dispatch('processToken', response.data['access_token'])
      return true
    } catch (error) {
      return false
    }
  },
  async updateEosId({ commit }, eosId) {
    try {
      await axios({
        method: 'PUT',
        url: '/api/v1/user/',
        headers: {'Authorization': `Bearer ${state.token}`},
        data: {'eos_id': eosId}
      })
      commit('setEosId', eosId)
    } catch (error) {
      console.log('There was an error setting EOS ID.', error)
    }
  },
  async steamLogin({ commit }, steamID) {
    try {
      const response = await axios({
        method: 'post',
        url: '/api/v1/user/steam_user',
        headers: {'Authorization': `Bearer ${state.token}`},
        data: {'id': steamID}
      })
      commit('setSteamUser', response.data)
    } catch (error) {
      console.log('There was an error setting Steam ID.', error)
    }
  },
  async refreshToken({ dispatch, state }) {
    if (!state.token) {
      return
    }
    try {
      const response = await axios({
        method: 'get',
        url: '/oauth2/token/refresh',
        headers: {'Authorization': 'Bearer ' + state.token}
      })

      dispatch('processToken', response.data['access_token'])
    } catch (error) {
      console.log('There was an error refreshing the JWT token.', error)
      dispatch('logout')
    }
  },
  logout({ commit }) {
    Cookies.remove('jwt')
    commit('setToken', null)
    router.push('/')
  },
  async steamLogout({ dispatch, commit, state }) {
    try {
      await axios({
        method: 'delete',
        url: '/api/v1/user/steam_user',
        headers: {'Authorization': `Bearer ${state.token}`}
      })
      commit('setSteamUser', null)
    } catch (error) {
      console.log('There was an error deleting SteamUser')
    }
    dispatch('refreshToken')
  }
}

const mutations = {
  setToken: (state, token) => {
    state.token = token
  },
  setEosId: (state, eosId) => {
    state.eosId = eosId
  },
  setSteamUser: (state, steamUser) => {
    state.steamUser = steamUser
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
