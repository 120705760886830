import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import HowItWorks from "@/views/HowItWorks"
import EventsList from "@/views/EventsList"
import Config from "@/views/Config"
import Contact from '@/views/Contact.vue'
import ExtensionSetup from "@/views/ExtensionSetup";

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/how-it-works',
    name: 'How It Works',
    component: HowItWorks
  },
  {
    path: '/events',
    name: 'Events List',
    component: EventsList
  },
  {
    path: '/config',
    name: 'Configuration',
    component: Config,
    beforeEnter: (to, from, next) => {
      if(!store.getters.getToken) {
        next('/')
      }
      next()
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/extension-setup',
    name: 'Extension Setup',
    component: ExtensionSetup
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
