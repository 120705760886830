<template>
  <div>
    <h3>Contact Us</h3>

    <p>
      To better support our Streamers in a more real-time manner, we use Discord for all our of communications.
    </p>

    <p>
      Click the button below to join our Discord server.
    </p>

    <a href="https://discord.gg/TttzEZdmrW" target="_blank"><img src="/static/img/join_discord.png" width="280"/></a>
  </div>

</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>

</style>