import axios from 'axios'

async function botTokenRequest(jwt, method) {
    let headers = {
        'Authorization': `Bearer ${jwt}`
    }
    return axios({
        method: method,
        url: '/api/v1/user/bot_token',
        headers: headers
    })
}

async function serverRequest(jwt, method, serverId) {
    let headers = {
        'Authorization': `Bearer ${jwt}`
    }
    return axios({
        method: method,
        url: `/api/v1/game_server/${serverId || ''}`,
        headers: headers
    })
}

const state = {
    botToken: null,
    servers: []
}

const getters = {
    getBotToken: state => {
        return state.botToken
    },
    getServers: state => {
        return state.servers
    }
}

const actions = {
    async createBotToken({ commit, rootGetters }) {
        try {
            let response = await botTokenRequest(rootGetters.getToken, 'post')
            commit('setBotToken', response.data.token)
            return response
        } catch (error) {
            console.warn(error.response.data.detail)
            throw error
        }
    },
    async deleteBotToken({ commit, rootGetters }) {
        try {
            let response = await botTokenRequest(rootGetters.getToken, 'delete')
            commit('setBotToken', null)
            return response
        } catch (error) {
            console.warn(error.response.data.detail)
            throw error
        }
    },
    async loadServers({ commit, rootGetters }) {
        try {
            let response = await serverRequest(rootGetters.getToken, 'get')
            commit('setServers', response.data)
            return response
        } catch (error) {
            console.warn('There was an error getting the Game Servers. ', error)
            throw error
        }
    },
    async getServer({ commit, rootGetters }, serverId) {
      try {
          let response = await serverRequest(rootGetters.getToken, 'get', serverId)
          commit('updateServer', response.data)
          return response
      } catch (error) {
          console.warn(`There was an error getting existing Server ID: ${serverId}`, error)
          throw null
      }
    },
    async createServer({ commit, rootGetters }) {
        try {
            let response = await serverRequest(rootGetters.getToken, 'post')
            commit('addServer', response.data)
            return response
        } catch (error) {
            console.warn('There was an error creating the Server Key. ', error)
            throw error
        }
    },
    async deleteServer({ commit, rootGetters}, serverId) {
        try {
            let response = await serverRequest(rootGetters.getToken, 'delete', serverId)
            commit('removeServer', serverId)
            return response
        } catch (error) {
            console.warn(`There was an error deleting Server ID: ${serverId}.`, error)
            throw error
        }
    },
    async addExistingServer({ commit, rootGetters }, serverId) {
        try {
            let response = await serverRequest(rootGetters.getToken, 'put', serverId)
            commit('addServer', response.data)
            return response
        } catch (error) {
            console.warn(`There was an error adding existing Server ID: ${serverId}`)
            throw error
        }
    }
}

const mutations = {
    setBotToken: (state, botToken) => {
        state.botToken = botToken
    },
    setServers: (state, servers) => {
        state.servers = servers
    },
    addServer: (state, server) => {
        state.servers.push(server)
    },
    updateServer: (state, server) => {
        for (let i = 0; i < state.servers.length; i++) {
            if (state.servers[i].id === server.id) {
                state.servers.splice(i, 1, server)
                break
            }
        }
    },
    removeServer: (state, serverId) => {
        for (let i = 0; i < state.servers.length; i++) {
            if (state.servers[i].id === serverId) {
                state.servers.splice(i, 1)
                break
            }
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
