<template>
<div>
  <b-alert :show="!steamUser" variant="danger">
    You have no Steam Account associated with your Twitch Account.
    No events will trigger in-game (ARK: Survival Evolved) until you do so.
    Please Login with Steam.
  </b-alert>

  <b-alert :show="!eosId" variant="danger">
    You have no EOS ASA ID associated with your Twitch Account.
    No events will trigger in-game (ARK: Survival Ascended) until you do so.
    Please add an EOS ASA ID below.
  </b-alert>

  <div id="asaSection" class="mb-4">
    <h3>ARK: Survival Ascended</h3>
    <p>
      To enable support for ARK: Survival Ascended, we will need you to provide your EOS ASA ID.
      This ID can be found by using the
      <a href="https://www.curseforge.com/ark-survival-ascended/mods/identity-helper" target="_blank">Identity Helper Mod</a>.
    </p>

    <b-form @submit.prevent="updateEOS">
      <b-form-group
        id="eosIDFormGroup"
        label="EOS ASA ID"
        label-for="eosInput"
        :description="eosIdStatusText"
      >
        <b-form-input
          id="eosInput"
          v-model="eosIdInput"
          @change="updateEosIdCheck"
          :state="eosIdValid"
          required
        ></b-form-input>
      </b-form-group>
    </b-form>

    <b-button
        class="m-1"
        variant="primary"
        @click="updateEosIdCheck"
        :disabled="!eosIdValid"
    >
      Save
    </b-button>
  </div>

  <div id="botSection" class="mb-4">
    <h3>Chaos Creator Twitch Bot</h3>
    <p>
      To capture the Custom Reward events in real-time, we utilize a custom Twitch Bot.
      This Bot will also enable and disable the Chaos Creator Rewards for your channel when it is active/inactive.
      Download the appropriate file for your system.
    </p>

    <ul>
      <li><a href="/files/bot/chaos_creator_bot_win.zip">Windows 7+ 64-bit</a></li>
      <li><a href="/files/bot/chaos_creator_bot_linux_amd64">Linux</a></li>
      <li><a href="/files/bot/chaos_creator_bot_darwin_amd64">Mac/Darwin 10.10+</a></li>
    </ul>

    <p>
      Create a Bot Token if you do not have one.
      You will need this to login with the Bot.
      You can create another if you lose it.
    </p>

    <div v-if="botToken">
      <p>You will only be able to see this token now. Copy it somewhere safe.</p>
      <pre>{{ botToken }}</pre>
    </div>
    <b-button variant="primary" @click="botTokenButton">{{ botButtonText }}</b-button>

    <p class="mt-1"><strong>NOTE: </strong> After BETA, the Bot will login through this website, and no longer need a token generated by you.</p>
  </div>

  <div id="serverSection">
    <h3>Server Keys</h3>
    <p>
      You will need to configure the ARK server with a Server Key for the companion mod on your server to identify itself with our backend servers.
      You can either create a new Server Key for a server you play on, share your existing Server Keys with other Streamers that you play with on the same server, or enter a Server Key that was shared with you by another Streamer that you play on a server with.
    </p>

    <p>
      To install a Server Key on an ARK game server, you will need to add <code>FreeServerKey=YOUR-FREE-SERVER-KEY</code> under the <code>[ArkChaosCreator]</code> section in <code>GameUserSettings.ini</code> (you may have to create this section if it does not exist).
      If you use the Chaos Creator Extension (Bits Version), you will just add <code>FreeServerKey=YOUR-FREE-SERVER-KEY</code> under your existing <code>ServerKey</code>.
    </p>

    <pre>[ArkChaosCreator]
ServerKey=A-BITS-SERVER-KEY
FreeServerKey=YOUR-FREE-SERVER-KEY</pre>
    <p><strong>Note:</strong> You can only have <strong>one</strong> Chaos Creator Channel Points Server Key in the <code>GameUserSettings.ini</code> file</p>
    <p><strong>Note:</strong> Chaos Creator Free Server Keys are <strong>NOT</strong> the same as Chaos Creator Extension (Bits Version). However, the Extension and Channel Points (this version) can be used together.</p>

    <div>
      <button class="btn btn-primary mr-2" @click="createServer">Create Server Key</button>
      <button class="btn btn-light-grey" @click="resetModal(); showExistingKeyModal=true">Add Existing Key</button>
      <div class="card-container row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 justify-content-center ml-0 mr-0 mt-2 mb-2 p-2" v-if="gameServers.length">
        <ServerCard class="col mb-3 ml-1 mr-1"
            v-for="gameServer in gameServers"
            :key="gameServer.id"
            :gameServer="gameServer"
        ></ServerCard>
      </div>
      <div class="alert alert-secondary mt-2" v-else>
        You have no Server Keys created. Please create one or add an existing key.
      </div>
    </div>
  </div>

  <b-modal
    id="addExistingKeyModal"
    v-model="showExistingKeyModal"
    title="Add Existing Server Key"
  >
    <b-form @submit.prevent="lookupServer">
      <b-form-group
        id="existingKeyFormGroup"
        label="Server Key"
        label-for="existingKeyInput"
        :description="existingKeyStatusText"
      >
        <b-form-input
          id="existingKeyInput"
          v-model="existingKey"
          @change="lookupServer"
          :state="existingKeyValid"
          required
        ></b-form-input>
      </b-form-group>

      <p v-if="existingServer">{{ existingServer.name }}</p>
    </b-form>

    <template #modal-footer>
      <div>
        <b-button
            class="m-1"
            variant="secondary"
            @click="showExistingKeyModal=false"
        >
          Cancel
        </b-button>
        <b-button
            class="m-1"
            variant="primary"
            @click="addExisting"
            :disabled="!existingKeyValid"
        >
          Add
        </b-button>
      </div>
    </template>
  </b-modal>

</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ServerCard from '@/components/ServerCard'

export default {
  name: "Config",
  components: {
    ServerCard
  },
  data: function() {
    return {
      eosIdInput: "",
      existingKey: null,
      existingKeyStatusText: 'Let\'s lookup the server.',
      existingKeyValid: false,
      existingServer: null,
      gameServersLoading: false,
      showExistingKeyModal: false,
      uuidExp: new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$'),
      severRefreshInterval: null
    }
  },
  computed: {
    ...mapGetters({
      jwt: 'getToken',
      eosId: 'getEosId',
      steamUser: 'getSteamUser',
      botToken: 'getBotToken',
      gameServers: 'getServers'
    }),
    botTokenText: function() {
      if (this.botToken) {
        return this.botToken
      }
      return 'No Bot Token Created'
    },
    botButtonText: function() {
      if (this.botToken) return 'Reset Bot Token'
      return 'Create Bot Token'
    },
    eosIdValid: function() {
      if (!this.eosIdInput) {
        return false
      }
      const regex = /^[0-9a-f]{32}$/
      return regex.test(this.eosIdInput)
    },
    eosIdStatusText: function() {
      if (!this.eosIdInput) {
        return "Enter EOS ASA ID to enable ASA support"
      }
      if (!this.eosIdValid) {
        return "Invalid EOS ASA ID format"
      }
      return "Valid EOS ASA ID"
    }
  },
  methods: {
    ...mapActions([
        "createBotToken",
        "deleteBotToken",
        "loadServers",
        "getServer",
        "createServer",
        "addExistingServer",
        "updateEosId",
        "refreshToken"
    ]),
    updateEosIdCheck: async function() {
      if (!this.eosIdValid) {
        return
      }
      await this.updateEosId(this.eosIdInput).then(async () => {
        await this.refreshToken()
      })
    },
    botTokenButton: function () {
      console.log('BUTTON')
      if (this.botToken) {
        this.deleteBotToken()
      } else {
        try {
          this.createBotToken()
        } catch (error) {
          this.deleteBotToken()
          this.createBotToken()
        }
      }
    },
    resetModal: function() {
      this.existingKey = null
      this.existingServer = null
      this.existingKeyValid = false
      this.existingKeyStatusText = 'Let\'s lookup the server.'
    },
    lookupServer: function() {
      let found = this.existingKey.trim().toLowerCase().match(this.uuidExp)
      if (found) {
        for (let i = 0; i < this.gameServers.length; i++) {
          if (this.gameServers[i].id === found[0]) {
            this.existingKeyStatusText = 'This Server Key is already associated with your account.'
            this.existingServer = this.gameServers[i]
            this.existingKeyValid = false
            return
          }
        }
        this.existingKeyStatusText = 'Looking...'
        this.getServer(found[0])
          .then((response) => {
            this.existingKeyStatusText = 'Is this the server you are looking for?'
            this.existingServer = response.data
            this.existingKeyValid = true
          })
          .catch(() => {
            this.existingKeyStatusText = 'Hmmmm. Not Found. Try again?'
            this.existingServer = null
            this.existingKeyValid = false
          })
      }
    },
    addExisting: function() {
      if (this.existingKeyValid) {
        let found = this.existingKey.trim().toLowerCase().match(this.uuidExp)
        this.addExistingServer(found[0])
        this.showExistingKeyModal = false
        this.resetModal()
      }
    }
  },
  beforeMount() {
    this.loadServers()
    this.serverRefreshInterval = setInterval(this.loadServers, 15 * 1000)
    this.eosIdInput = this.eosId
  },
  beforeDestroy() {
    clearInterval(this.serverRefreshInterval)
  }
}
</script>

<style scoped>
h1, h2, h3, h4, h5 {
  color: #FF550B;
}
pre {
  background-color: #cccccc;
  border: 1px black solid;
  border-radius: 0.4em;
  padding: 0.5em;
}
pre:hover {
  cursor: pointer;
}

.btn-light-grey {
  background-color: #888;
}

.btn-light-grey:hover {
  background-color: #666;
}

.card-container {
  background-color: #606060;
  border-radius: 0.4em;
}
</style>