<template>
  <div class="card bg-secondary shadow">
    <div class="card-body">
      <h6>Key</h6>
      <p><small>{{ gameServer.id }}</small></p>
      <h6>Name</h6>
      <p><small>{{ gameServer.name }}</small></p>
      <b-button
          v-b-modal="'modal-' + gameServer.id"
          @click="showDeleteModal=true"
          class="float-right btn-secondary-medium"
      >
        <FontAwesomeIcon icon="trash" />
      </b-button>
      <h6>Last Checkin</h6>
      <p><small>{{ lastSeen | moment("from", "now") }}</small></p>

    </div>
    <b-modal
        :id="'modal-' + gameServer.id"
        v-model="showDeleteModal"
        title="Remove Server">
      <p>Disassociate the server key ({{ gameServer.id }}) for {{ gameServer.name }} from your account?</p>
      <p>If you created this key, and other Streamers are using it, this will remove it from their accounts as well.</p>
      <p>If you added this key as an existing key, it will only remove it from your account.</p>
      <p>Are you sure you want to do this?</p>

      <template #modal-footer>
        <div>
          <b-button
            class="m-1"
            variant="secondary"
            @click="showDeleteModal=false"
          >
            Cancel
          </b-button>
          <b-button
              class="m-1"
              variant="primary"
              @click="deleteServer(gameServer.id); showDeleteModal=false;"
          >
            OK
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapActions } from 'vuex'

library.add(faTrash)

export default {
  name: "ServerCard",
  components: {
    FontAwesomeIcon
  },
  props: {
    gameServer: Object,
  },
  data: function() {
    return {
      showDeleteModal: false
    }
  },
  computed: {
    lastSeen: function() {
      if (this.gameServer.last_seen) {
        return this.gameServer.last_seen + 'Z'
      }
      return 'Never'
    }
  },
  methods: {
    ...mapActions([
      "deleteServer",
    ])
  }
}
</script>

<style scoped>

h1, h2, h3, h4, h5, h6 {
  color: #FF550B;
}

.card {
  min-width: 340px;
}
.card-title {
  color: #FF550B;
}
hr {
  background-color: #ccc;
}
.row {
  text-align: center;
}
.small-text {
  font-size: 0.8rem;
}
.card-footer .col {
  padding: 0;
}
.card-footer .form-control-sm {
  padding: 0;
  height: calc(1em + 0.5rem + 2px);
  color: #e8e8e8;
  text-align: center;
  background-color: #666;
  border: 1px solid #e8e8e8;
}
.card-footer .custom-control.custom-switch.pt-2 {
  padding-top: 0.5rem !important;
}
.card-footer .col .pt-2 {
  padding-top: 0.6rem !important;
}

</style>
