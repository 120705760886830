import Vue from 'vue'
import Vuex from 'vuex'

import jwt from './modules/jwt'
import config from './modules/config'
import rewards from './modules/rewards'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    jwt,
    config,
    rewards
  }
})
