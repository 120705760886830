<template>
  <div class="ml-1">
    <b-button v-if="jwt && !steamUser" class="bg-steam text-steam border-1" href="/openid">{{  buttonText }}</b-button>
    <b-button ref="userButton" v-else-if="jwt && steamUser" class="bg-steam text-steam border-1" @click="logout" @mouseover="hover=true" @mouseleave="hover=false">
      <b-avatar size="1.45em" variant="secondary" :src="avatar" class="mr-3" v-if="!hover"></b-avatar> {{ buttonText }}
    </b-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Cookies from "js-cookie";

export default {
  name: "LoginSteamButton",
  data: function() {
    return {
      state: null,
      pattern: /\d{17}$/g,
      loading: false,
      hover: false
    }
  },
  computed: {
    ...mapGetters({
      jwt: 'getToken',
      steamUser: 'getSteamUser'
    }),
    realm: function() {
      return `${location.origin}${location.pathname}`
    },
    redirectUrl: function() {
      return `https://steamcommunity.com/openid/login?openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.mode=checkid_setup&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&openid.realm=${encodeURIComponent(this.realm)}&openid.return_to=${encodeURIComponent(this.realm)}%3Fopenid_complete%3Dyes%26state%3D${this.state}`
    },
    username: function() {
      if (this.steamUser) {
        return this.steamUser['username']
      }
      return null
    },
    avatar: function() {
      if (this.steamUser) {
        return this.steamUser['avatar_url']
      }
      return null
    },
    buttonText() {
      if (this.loading) {
        return 'Getting Info...'
      }
      if (!this.steamUser) {
        return 'Login with Steam'
      }
      if (this.hover) {
        return 'Change Steam Account'
      }
      return this.username
    }
  },
  methods: {
    ...mapActions({
      'login': 'steamLogin',
      'logout': 'steamLogout'
    }),
    redirect: function() {
      this.state = this.uuid4()
      Cookies.set('state', this.state)
      location.href = this.redirectUrl
    },
    submit: async function(steamID) {
      this.error = null
      this.loading = true

      if (!await this.login(steamID)) {
        this.error = 'Steam Login Failed'
      }
      this.loading = false
    },
    setButtonSize: function() {
      if (this.buttonText !== this.username) {
        setTimeout(this.setButtonSize, 300)
        return
      }
      this.$refs.userButton.style.minWidth = `${this.$refs.userButton.style.offsetWidth}px`
      this.$refs.userButton.style.minHeight = `${this.$refs.userButton.style.offsetHeight}px`
    },
    uuid4: function () {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      )
    }
  },
  beforeMount() {
    if ('openid.claimed_id' in this.$route.query
        && 'state' in this.$route.query
        && Cookies.get('state')
        && this.jwt) {
      // Validate Query Param State against Cookie State
      const state = Cookies.get('state')
      Cookies.remove('state')
      if (this.$route.query['state'] !== state) {
        return false
      }

      const matches = this.pattern.exec(this.$route.query['openid.claimed_id'])
      if (!matches) {
        return false
      }

      console.log(matches[0])
      this.$router.push(this.$route.path)

      // Login to backend with returned code
      this.submit(matches[0])
    }
  },
  mounted() {
    setTimeout(this.setButtonSize, 300)
  }
}
</script>

<style scoped>
.btn {
  min-width: 200px;
  border-color: #171717;
}

.bg-steam {
  background-color: #1b2838;
}

.text-steam {
  color: #c7d5e0;
}
</style>