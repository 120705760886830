<template>
<div>
  <h1>How It Works</h1>
  <p><strong>
    This page is the setup guide for the Channel Points/Custom Rewards version.
    For setup instructions for the Bits Extension, go <router-link to="/extension-setup">here</router-link>.
  </strong></p>
  <p>
    Twitch allows developers to hook into Channel Points through a very different method
    in comparison to Extensions and Bits. This means Chaos Creator has to interface with
    Twitch in a much different way, as well, to use these Channel Points. However,
    Chaos Creator is designed to work in any configuration (Bits only, Free/Channel Points only, or both).
  </p>
  <p>
    The following list is the order of operations for Free (Channel Points) Viewer -> Game Event:
  </p>
  <ol>
    <li>Streamer runs a bot that intercepts Custom Reward events via Twitch PubSub while they stream.</li>
    <li>Viewer redeems Chaos Creator Custom Reward using channel points.</li>
    <li>Bot intercepts the Chaos Creator Custom Reward event.</li>
    <li>Bot submits event to Mod Backend API queue.</li>
    <li>Chaos Creator ARK Game Mod checks into Backend API and retrieves event queue.</li>
    <li>Chaos is created!</li>
  </ol>
  <p>
    There are 4 main parts that make the Free (Channel Points) Chaos Creator work:
  </p>
  <ul>
    <li>Twitch Sign In and Steam Sign In</li>
    <li>Bot Install and Configuration</li>
    <li>Mod Install and Configuration</li>
    <li>Custom Channel Points Rewards</li>
  </ul>

  <p>
    While this page may seem a bit lengthy, it is a short process to get setup; and following
    this guide should have you up and running within 15 minutes. We are also looking for better
    ways to setup Custom Channel Points Rewards in the future, so stay tuned!
  </p>

  <h3>Twitch Sign In</h3>
  <p>
    We will need to associate your account with our site.
    We only request 3 permissions in total for the entire site and bot from Twitch.
  </p>
  <ul>
    <li>User Info (Username, Avatar, Channel ID, Status)</li>
    <li>Chat Read</li>
    <li>Custom Rewards PubSub</li>
  </ul>
  <p>
    If you haven't logged into Twitch already, please do so
  </p>
  <LoginTwitchButton class="mb-4" />

  <h3>Steam Sign In</h3>
  <p>
    We will need to know what your already public Steam 64 ID is so that the
    Mod can identify you in the Game Server. Steam Open ID allows you to
    share that ID without sharing credentials. If you haven't logged into
    Steam already, please do so.
  </p>
  <LoginSteamButton class="mb-4" />

  <h3>Chaos Creator Twitch Bot</h3>
  <p>
    Once you are logged in, you can download the Chaos Creator Twitch Bot from the
    <router-link to="/config">Configuration</router-link> page, and create a Bot Token. Run the Bot and login with the
    Bot Token and Twitch. The bot will enable and disable Chaos Creator Rewards when it is active/inactive.
    This Bot can run on any machine that has access to the internet (Desktop, Server, another machine, etc).
  </p>

  <h3>Mod Install and Configuration</h3>
  <p>
    The mod installation and configuration is almost identical to the Bits Extension setup.
    If you haven't setup a <strong>Free (Channel Points)</strong> Server Key yet, you will
    need to either generate a Server Key, or get a Server Key from a fellow Streamer that
    you share an ARK server with that already has the Chaos Creator Mod installed and setup.
    These Keys can be setup on the <router-link to="/config">Configuration</router-link> page (must be logged in to view).
  </p>
  <p>
    Install the <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2187784730" target="_blank">mod</a>
    on your private server.
  </p>
  <p>
    <strong>IMPORTANT:</strong> A server can only use <strong>one of each</strong> Bits Extension
    Server Key and Free (Channel Points) Server Key. They <strong>ARE</strong> different keys!
  </p>
  <h5>GameUserSettings.ini Examples:</h5>
  Bits Extension AND Free (Channel Points) Keys
  <pre>
[ArkChaosCreator]
ServerKey=123e4567-e89b-12d3-a456-426614174000
FreeServerKey=abcdef12-a34f-56c7-d890-123456789000</pre>


  Free (Channel Points) Key Only
  <pre>
[ArkChaosCreator]
FreeServerKey=abcdef12-a34f-56c7-d890-123456789000</pre>

  Bits Extension Key Only
  <pre>
[ArkChaosCreator]
ServerKey=123e4567-e89b-12d3-a456-426614174000</pre>

  <p>
    See the <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2187784730" target="_blank">mod page</a>
    and <a href="https://blitzfire911.com/" target="_blank">Blitzfire911.com</a>
    for more information on the mod configuration.
  </p>

  <h3>Custom Channel Points Rewards</h3>
  <p>
    Once you are logged in with Twitch, visit the <router-link to="/events">Events</router-link> page and install the
    Chaos Creator Rewards into your Channel to your liking. You will be able to configure points cost and cooldowns
    for each event. You may also uninstall all Chaos Creator Rewards at anytime from this page.
  </p>
</div>
</template>

<script>
import LoginTwitchButton from "@/components/LoginTwitchButton";
import LoginSteamButton from "@/components/LoginSteamButton";

export default {
  name: "HowItWorks",
  components: {
    LoginTwitchButton,
    LoginSteamButton
  }
}
</script>

<style scoped>
h1, h2, h3, h4, h5 {
  color: #FF550B;
}
pre {
  background-color: #cccccc;
  border: 1px black solid;
  border-radius: 0.4em;
  padding: 0.5em;
}
</style>