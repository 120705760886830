<template>
  <div class="ml-1">
    <b-button class="bg-twitch text-twitch" v-if="!jwt" @click="redirect">{{ buttonText }}</b-button>
    <b-button ref="userButton" v-else class="bg-twitch text-twitch my-auto" @click="logout" @mouseover="hover=true" @mouseleave="hover=false">
      <b-avatar size="1.45em" variant="secondary" :src="avatar" class="mr-3" v-if="!hover"></b-avatar> {{ buttonText }}
    </b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Cookies from "js-cookie";

export default {
  name: "LoginTwitchButton",
  data: function() {
    return {
      state: null,
      loading: false,
      error: null,
      hover: false
    }
  },
  computed: {
    ...mapGetters({
      jwt: 'getToken',
      twitchUser: 'getTwitchUser'
    }),
    buttonText: function() {
      if (this.loading) {
        return 'Validating...'
      }
      if (this.twitchUser) {
        if (!this.hover) {
          if (this.$refs.userButton){
            //
            console.log(`${this.$refs.userButton.clientWidth}px`)
          }

          return this.username
        }
        return 'Logout'
      }
      return 'Login with Twitch'
    },
    redirectUrl: function() {
      return `https://id.twitch.tv/oauth2/authorize?client_id=uudmnystqrnrqxkd1dt01x48x5i84c&redirect_uri=${encodeURIComponent(location.origin)}%2F&scope=user_read%20channel%3Aread%3Aredemptions%20channel%3Amanage%3Aredemptions&state=${this.state}&response_type=code`
    },
    username: function() {
      if (this.twitchUser) {
        return this.twitchUser['username']
      }
      return null
    },
    avatar: function() {
      if (this.twitchUser) {
        return this.twitchUser['avatar_url']
      }
      return null
    }
  },
  methods: {
    ...mapActions(['login', 'logout', 'refreshToken']),
    redirect: function() {
      this.state = this.uuid4()
      Cookies.set('state', this.state)
      location.href = this.redirectUrl
    },
    submit: async function(code) {
      this.error = null

      // Lock form and login
      this.loading = true
      if (!await this.login(code)) {
        this.error = 'Login Failed'
      }
      this.loading = false
      this.setButtonSize()

    },
    setButtonSize: function() {
      if (this.buttonText !== this.username) {
        setTimeout(this.setButtonSize, 300)
        return
      }
      this.$refs.userButton.style.minWidth = `${this.$refs.userButton.offsetWidth}px`
      this.$refs.userButton.style.minHeight = `${this.$refs.userButton.offsetHeight}px`
    },
    uuid4: function () {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      )
    }
  },
  beforeMount() {
    // Check Query Params and State Cookie Exist
    if (window.location.search && Cookies.get('state') && !this.jwt) {
      const params = new URLSearchParams(window.location.search)
      // Validate Query Param State against Cookie State
      const state = Cookies.get('state')
      Cookies.remove('state')
      if (params.get('state') !== state) {
        return false
      }

      // Verify Query Param Code exists
      if (!params.get('code')) {
        return false
      }

      // Login to backend with returned code
      this.submit(params.get('code'))
    }
  },
  mounted() {
    setTimeout(this.setButtonSize, 300)
  }
}
</script>

<style scoped>
.bg-twitch {
  background-color: #9146FF;
}

.text-twitch {
  color: #F0F0FF;
}

button {
  min-width: 200px;
}


</style>