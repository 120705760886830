<template>
  <div>
    <h3>Bits Extension Setup Guide</h3>
    <p>
      WIP, check back for updates.
    </p>
  </div>
</template>

<script>
export default {
  name: "ExtensionSetup"
}
</script>

<style scoped>

</style>